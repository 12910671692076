@font-face {
  font-family: 'Open Sans';
  src: url(../assets/fonts/Open_Sans/OpenSans-Regular.ttf) format('truetype');
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

* {
  user-select: none;
  --webkit-user-select: none;
  scrollbar-width: thin;
}

input {
  user-select: text;
  --webkit-user-select: text;
}

body {
  margin: 0;
}

img.build-preview {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

::-webkit-scrollbar { 
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
::-webkit-resizer {
  background-color: #666;
}
